import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton } from '@mui/material';
import CircularIndeterminate from '../../../components/loading/Loading';
import { toast } from 'react-toastify';
import { AddAdmins } from '../../../postApi/Axios';

export default function AddAdmin({ adminToken,refetchAdmin,setOpenadd }) {
    const [loading,setloading]=useState(false)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');


    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleAddAdmin = async(e)=>{
        e.preventDefault();
        setloading(true)
        try {
            const response = await AddAdmins(adminToken,{
                name: name,
                email: email,
                password: password,
                role: role
            });
            if(response.status===200){
                refetchAdmin()
                toast.success('Admin added successfully!');
                setOpenadd(false)
            }
        } catch (error) {
            toast.error(`Failed to add admin: ${error.message}`);
        }finally{
            setloading(false)
        }
    }
    return (
        <>
        {loading && <CircularIndeterminate />}
        <div className='EditAdmin'>
            <form onSubmit={handleAddAdmin}>
                <TextField
                    id="admin-name"
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    id="admin-email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                />

                <TextField
                id="Password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={handlePasswordChange}
                fullWidth
                sx={{ marginBottom: 2 }}
            />

                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel id="role-select-label">Role</InputLabel>
                    <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={role}
                        label="Role"
                        onChange={handleRoleChange}
                    >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="editor">Editor</MenuItem>
                        <MenuItem value="moderator">Moderator</MenuItem>
                    </Select>
                </FormControl>
                
                <div>
                    <IconButton
                        type='submit'
                        className='saveadmin'
                        sx={{ width: '100%', backgroundColor: '#df3a37', color: "#fff", fontSize: "18px", fontWeight: 600, borderRadius: '8px', padding: '15px' }}
                    >
                        Save
                    </IconButton>
                </div>
            </form>
        </div>
        </>
    )
}
